/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";
@import '~@fortawesome/fontawesome-free/css/all.min.css';

@import '../src/assets/scss/custom-bootstrap/bootstrap';
@import '../src/assets/scss/css-utilities.scss';
@import '../src/assets/scss/form/form.scss';
@import '../src/assets/scss/typography/typography.scss';
@import '../src/assets/scss/ngx-carousel.scss';

html{
  scroll-behavior: auto;
  min-height: 100vh;
}

main {
  height: 100%;
}
app-root {
  display: block;
}

body {
  background-color: #f2f5fa;
  min-height: 100vh !important;
}

*:not(i, .material-icons, .material-icons-outlined) {
  font-family: 'Titillium Web' !important;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

@font-face {
  font-family: 'Material Icons';
  font-display: block;
}

blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.btn.disabled, .btn:disabled {
  cursor: no-drop;
}

.btn-action:hover {
  cursor: pointer;
  filter: brightness(80%);
}

.snack .snack-action {
  text-transform: none !important;
  color: var(--primary) !important;
}
.snack .snack-text {
  color: #fff !important;
}

/* Modal NauMatch */
.modal-naumatch {
  position: absolute;
  width: 100%;
  height: 100%;
  left:0px;
  top:0px;
  overflow: auto;
  background-color: #666666;

  .title {
    font-size: 22px;
    font-weight: 600;
    color: #13103e;
  }
  .card {
    background-color: #edf0f6;
    border-radius: 12px;
    max-width: 500px;
    margin: 2.5rem 0;
    border: none;
  }
  .card-550 {
    max-width: 550px;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Scroll */
// .scroll-blue {
//   ::-webkit-scrollbar-track {
//     background-color: #d8d8d8;
//     border-radius: 5px;
//   }
//   ::-webkit-scrollbar {
//       width: 10px;
//       height: 10px;
//       background: #d8d8d8;
//       border-radius: 5px;
//   }
//   ::-webkit-scrollbar-thumb {
//       background:  #13103e;
//       border-radius: 5px;
//   }
// }

// .table-responsive {
//   position:relative;
// }

// .table-responsive  table {
//   width:100%;

// }

// .table-responsive table thead th {
//   position:absolute;   
//   width: auto;
//   z-index:2;
// }

.table-responsive::-webkit-scrollbar-track {
  background-color: #d8d8d8;
  border-radius: 5px;
}

.table-responsive::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #d8d8d8;
  border-radius: 5px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background:  #13103e;
  border-radius: 5px;
}

// Card Vagas
.card-vacancy {
  display:block;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 6px 6px 24px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffff;
  font-size: 16px;
}
