carousel {
  .carousel-indicators {
    bottom: -45px;
  }
  .carousel-indicators li {
    margin: 0 5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    opacity: 0.3;
    background-color: #13103e;
  }
  .carousel-indicators li.active {
    opacity: 1;
  }
  .carousel-control-prev-icon {
    position: absolute;
    background-image: url(/assets/img/home/arrow-left.svg);
    width:30px;
    height: 30px;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
  }
  .carousel-control-next-icon {
    position: absolute;
    background-image: url(/assets/img/home/arrow-right.svg);
    width:30px;
    height: 30px;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  carousel {
    .carousel-control-prev, .carousel-control-next {
      display: none;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  carousel {
    .carousel-control-prev, .carousel-control-next {
      display: none;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  carousel {
    .carousel-indicators {
      display: none;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  carousel {
    .carousel-indicators {
      display: none;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  carousel {
    .carousel-indicators {
      display: none;
    }
  }
}



