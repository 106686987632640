/* it creates my-6, py-6, mx-6 and all default bootstrap spacings  with a new rem value */

$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
  xxxl: 1600px
);

// $container-max-widths: (
//   xxxs: 0,
//   xxs: 320px,
//   xs: 568px,
//   sm: 667px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1500px,
//   xxxl: 1600px
// );
