.box {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
    background-color: #fff;
    .box-header {
        background-color: #fff;
        padding: 1.25rem;
    }
}

.subtitles {
    border: 1px solid rgba(19, 16, 62, 0.1);
    border-radius: 12px;
    .text {
        font-size: 1.25rem;
        font-weight: bold;
        color: var(--secondary);
        margin: 0 !important;
    }
}

.no-button {
    background-color: transparent;
    color: transparent;
    border: none;
}

.btn-action {
    outline: 0;
    border: none;
}

.btn-rounded {
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    border-radius: 50%;
}

.btn-rounded:disabled, .btn-rounded.disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.circleIcon {
    box-shadow: $box-shadow;
    background-color: white;
    border-radius: 50%;
    padding: 0.25rem;
}

.avatar {
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

.avatar {
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.avatar-person {
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.vertical-middle {
    vertical-align: middle;
}

.d-inline-flex {
    display: inline-flex;
}

.text-line-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}