$font-size-xl: $font-size-base * 1.25;
/* input and button values */
$input-btn-padding-x-xl: 1.25rem;
$input-btn-padding-y-xl: .75rem;
$input-btn-line-height-xl: $line-height-xl;

/* input values */
$input-font-size-xl: $font-size-xl;
$input-border-radius-xl: $border-radius-xl;
$input-padding-x-xl: $input-btn-padding-x-xl;
$input-padding-y-xl: $input-btn-padding-y-xl;
$input-line-height-xl: $input-btn-line-height-xl;
$input-height-xl: add($input-line-height-xl * 1em, add($input-padding-y-xl * 2, $input-height-border, false));

/* button values */
$btn-padding-x-xl: $input-btn-padding-x-xl;
$btn-padding-y-xl: $input-btn-padding-y-xl;
$btn-font-size-xl: $font-size-xl;
$btn-line-height-xl: $input-btn-line-height-xl;
$btn-border-radius-xl: $border-radius-xl;

/* custom */
.form-control-xl {
    height: $input-height-xl !important;
    padding: $input-padding-y-xl $input-padding-x-xl !important;
    @include font-size($input-font-size-xl !important);
    line-height: $input-line-height-xl !important;
    @include border-radius($input-border-radius-xl !important);
};

.btn-xl {
    border-width: 2px;
    @include button-size($btn-padding-y-xl, $btn-padding-x-xl !important, $btn-font-size-xl !important, $btn-line-height-xl !important, $btn-border-radius-xl !important);
}