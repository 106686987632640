
@mixin checkboxColor($color) {
    background-color: $color;
}
  
@mixin checkboxShadow($color) {
    box-shadow: inset 1em 1em $color;
}
  
@each $color, $value in $theme-colors {
    input[type="checkbox"].custom-#{$color}:checked {
        @include checkboxColor($value)
    }
}
  
input[type="checkbox"].custom-primary::before  {
    @include checkboxShadow(white)
}

input[type="checkbox"].custom-secondary::before  {
    @include checkboxShadow(black)
}

input[type="checkbox"].custom-light-green::before  {
    @include checkboxShadow(white)
}

input[type="checkbox"].custom-light-orange::before  {
    @include checkboxShadow(white)
}

input[type="checkbox"].custom-dark-red::before  {
    @include checkboxShadow(white)
}

  
input[type="radio"].custom:checked::before  {
    @include checkboxShadow(black)
}