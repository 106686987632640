$primary: #1691d0 ;
$secondary: #13103e ;
$light-green: #9ac23c;
$light-orange: #f8b746;
$dark-red: #c90000;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "light-green": $light-green,
    "light-orange": $light-orange,
    "dark-red": $dark-red,
);

@each $color, $value in $theme-colors {
    .fill-#{$color} {
        fill: $value;
    }
}

.fill-primary2 {
    fill: $primary !important;
}

/* override */
$font-size-base: 1rem;
$input-color: $secondary;
$input-bg: white;
$input-border-color: #d8d8d8;


$border-radius: .35rem;
$border-radius-xl: .5rem;
$border-radius-lg: .4rem;
$font-size-lg: $font-size-base * 1.125;
$border-radius-sm: .3rem;
$line-height-xl: 1.5;


$enable-responsive-font-sizes: true;

