@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';
@import "./props.scss";

.display-1, .display-2,  .display-3, .display-4, .display-5, .display-6 {
    font-weight: 700;
}

.display-1 {
    @include responsive-font-size($display-1);
}

.display-2 {
    @include responsive-font-size($display-2);
}

.display-3 {
    @include responsive-font-size($display-3);
}

.display-4 {
    @include responsive-font-size($display-4);
}

.display-5 {
    @include responsive-font-size($display-5);
}

.display-6 {
    @include responsive-font-size($display-6);
}

.body-xl {
    @include responsive-font-size($body-1);
}

.body-lg {
   @include responsive-font-size($body-2);
}

.body-md {
    @include responsive-font-size($body-3)
}

.body-md-2 {
    @include responsive-font-size($body-4)
}

.body-sm {
    @include responsive-font-size($body-5)
}


.caption {
    opacity: 0.75;
    font-size: 1rem;
}