$display-1: 3.875rem;
$display-2: 3.75rem;
$display-3: 3.25rem;
$display-4: 2.375rem;
$display-5: 2.125rem;
$display-6: 1.875rem;

$body-1: 1.75rem;
$body-2: 1.625rem;
$body-3: 1.5rem;
$body-4: 1.125rem;
$body-5: 0.9375rem;

$font-base: 1rem;
$bold-weight: 700;
$semi-bold-weight: 600;

$tag-color: var(--secondary) !default;

/* font-weight utils */
.bold {
    font-weight: $bold-weight !important;
}

.semi-bold {
    font-weight: $semi-bold-weight !important;
}

.italic {
    font-style: italic;
}

h1,h2,h3,h4,h5,h6,p,span, b, label, th, td {
    color: $tag-color;
}

a {
    color: var(--primary);
    cursor: pointer;
}

a:after, a:hover {
    text-decoration: underline;
}
