*,
*:before,
*:after {
  box-sizing: border-box;
}

input[type="radio"].custom {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.25em;
  height: 1.25em;
  font-size: $font-size-base;
  border: 0.175em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"].custom.xl {
  width: 1.5em;
  height: 1.5em;
}

input[type="radio"].custom::before {
  content: "";
  width: .85em;
  height: 85em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="radio"].custom.xl::before {
  content: "";
  width: 1em;
  height: 1em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="radio"].custom:checked::before {
  transform: scale(1);
}

input[type="checkbox"].custom {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.25em;
  height: 1.25em;
  font-size: $font-size-base;
  border: 0.175em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"].custom.xl {
  width: 1.5em;
  height: 1.5em;
}

input[type="checkbox"].custom::before {
  content: "";
  width: .85em;
  height: .85em;
  box-shadow: inset .85em .85em white;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
}

input[type="checkbox"].custom.xl::before {
  width: .85em;
  height: .85em;
  box-shadow: inset .85em .85em white;
}

input[type="checkbox"].custom:checked::before {
  transform: scale(1);
}

input[type="checkbox"].custom:disabled {
  /* TODO: check correct disabled color */
  --form-control-color: grey;
  color: grey;
  cursor: not-allowed;
}


select {
  padding-right: 24px !important;
  appearance: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.select-box {
  position: relative;
}

.select-box:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: '\f107';
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.form-control-xl.select-box:after {
  font-size: 14px;
}