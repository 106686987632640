.input-box {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #d8d8d8;
    background-color: #e9ecef;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    &:focus {
        color: #d8d8d8;
        background-color: #fff;
        border-color: #75c7f0;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(22 145 208 / 25%);
    }

    input {
        border: none !important;
        box-shadow: none !important;
    }
}

.input-icon-box {
    position: relative;

    i {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        font-size: 16px;
    }

    input,
    select {
        padding-left: calc(16px + 12px + 12px);
    }
}

.form-check {
    cursor: pointer;
    padding: 0px;
    display: inline-block;

    &.checked {
        .check {
            border: 1px solid #13103e;
        }

        .check-form {
            background-color: #13103e !important;
        }
    }

    .check {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid #cccccc;

        .check-form {
            margin: 3px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #cccccc;
        }
    }

    .label {
        vertical-align: middle;
        color: #3f3d56;
    }
}


.form-password-box {
    position: relative;
    .material-icons {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        cursor: pointer;
    }
}